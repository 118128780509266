/* app.css */

* {
    box-sizing: border-box;
  }
  
  html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  
  .main {
    width: 100%; /* Full width */
    flex-grow: 1;
    padding: 16px; /* Standard padding */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center children horizontally */
    justify-content: flex-start; /* Align children to the top */
    flex-grow: 1; /* Allow content to grow */
  }
  
